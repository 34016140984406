<template>
  <b-row>
    <b-col cols="12">
      <divisoria-descricao
        v-if="value.razaoSocial"
        :titulo="`Empresa: ${value.cadastroFederal} - ${value.razaoSocial}`"
      />
      <divisoria-descricao
        titulo="Quadro societário"
        descricao="Informações padrões sobre os sócios"
        espaco-extra
      >
        <template slot="valor-extra">
          <div style="float:right;">
            <b-btn
              variant="none"
              class="btn btn-outline-primary mr-1"
              size="sm"
              @click="novo()"
            >
              <feather-icon
                style="margin-right:0.25rem"
                icon="PlusIcon"
              />Novo
            </b-btn>
          </div>
        </template>
      </divisoria-descricao>

      <div v-if="value.socios">
        <div
          v-for="(item, index) in value.socios"
          :key="index"
          class="mb-3"
        >
          <empresa-socios
            v-model="value.socios[index]"
            @remover-socio="removerSocio(index)"
          />
          <b-row>
            <b-col cols="12">
              <hr
                class="mx-0 mt-1 mb-1"
                style="border-bottom: 1px solid #a3a4a66b !important;"
              >
            </b-col>
          </b-row>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<style lang="scss">
.accordion {
  background-color: rgb(242, 242, 242);
  border-radius: 5px;
  padding: 5px;
}

.accordion.disabled {
  background-color: rgb(248, 248, 248);
  color: rgb(150, 150, 150);
}
</style>

<script>
// import { Collapse } from 'vue-collapsed'
// import 'vue3-rich-accordion/accordion-library-styles.css'

export default {
  name: 'SecaoSocios',
  components: {
    DivisoriaDescricao: () => import('@pilar/components/divisoria-descricao/DivisoriaDescricao.vue'),
    EmpresaSocios: () => import('./components/EmpresasSocio.vue'),
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  created() {
  },
  methods: {
    novo() {
      this.value.socios.push({ show: false })
    },
    removerSocio(index) {
      this.$confirmar('Remover?', 'Tem certeza que deseja excluir o registro?', 'warning')
        .then(result => {
          if (result.value) {
            this.value.socios.splice(index, 1)
          }
        })
    },
  },
}
</script>
